/* eslint-disable @typescript-eslint/no-use-before-define */
import { DialogDismiss } from "@ariakit/react";
import clsx from "clsx";
import { ReactNode, useMemo } from "react";

import { atlasClose } from "../../icons";
import { Component, createComponentUtils, Props } from "../__utils/atlas";
import { Button } from "../button/Button";
import { ButtonGroup } from "../button/ButtonGroup";

// config
// ------
const COMPONENT_NAME = "View";

const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

export type ViewProps<As extends keyof JSX.IntrinsicElements = "div" | "form"> =
  Props<As> & {
    as?: As;
    header?: HeaderProps | null;
    CustomHeader?: ReactNode;
    footer?: FooterProps | null;
    CustomFooter?: ReactNode;
    content?: ContentProps;
  };

export const View = createComponent<Component<ViewProps>>(
  ({
    as: ViewWrapper = "div",
    header,
    CustomHeader,
    footer,
    CustomFooter,
    content,
    children,
    ref,
    ...props
  }) => {
    return (
      <ViewWrapper
        {...props}
        // @ts-expect-error TS can't infer ref type
        ref={ref}
        className={clsx(el`layout`, props.className)}
      >
        {header !== null && !CustomHeader && <Header {...header} />}
        {CustomHeader}
        <Content
          {...content}
          hideHeaderBorder={header?.hideBorder}
          hideFooterBorder={footer?.hideBorder}
        >
          {children}
        </Content>
        {footer && <Footer {...footer} />}
        {CustomFooter}
      </ViewWrapper>
    );
  },
  {
    treeName: "View",
  }
);

export type ContentProps = Props<"div"> & {
  title?: string;
  subTitle?: string;
  hideHeaderBorder?: boolean;
  hideFooterBorder?: boolean;
};

export const Content = createComponent(
  ({
    title,
    subTitle,
    children,
    hideHeaderBorder,
    hideFooterBorder,
    ...props
  }: ContentProps) => {
    return (
      <div
        {...props}
        className={clsx(
          el`content`,
          {
            "-mb-6": hideFooterBorder,
            "-mt-6": hideHeaderBorder,
          },
          props.className
        )}
      >
        {!!(title || subTitle) && (
          <div
            className={clsx("space-y-2", {
              "mb-4": !!children,
            })}
          >
            {title && <div className="text-h3">{title}</div>}
            {subTitle && (
              <div className="text-body-md text-subtle">{subTitle}</div>
            )}
          </div>
        )}
        {children}
      </div>
    );
  },
  { treeName: "Content" }
);

export type HeaderProps = Props<"div"> & {
  title?: string;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  hideBorder?: boolean;
};

export const Header = createComponent(
  ({ title, leftActions, rightActions, hideBorder, ...props }: HeaderProps) => {
    const LeftActions = useMemo(() => {
      if (leftActions) {
        return leftActions;
      }

      if (leftActions === null) {
        return null;
      }

      return (
        <DialogDismiss>
          <Button
            isGhost
            size="small"
            icon={atlasClose}
            aria-label="Close dialog"
          />
        </DialogDismiss>
      );
    }, [leftActions]);

    return (
      <div
        {...props}
        className={clsx(el`header`, props.className, {
          hideBorder,
        })}
      >
        <div className="flex items-center justify-center px-[1.5rem] h-[4rem]">
          <ButtonGroup
            className="min-w-[2rem] flex-1"
            isGhost
            size="small"
            negativeMargin="left"
          >
            {LeftActions}
          </ButtonGroup>
          <div className="flex-2 text-center grow">{title}</div>
          <ButtonGroup
            className="min-w-[2rem] flex-1 justify-end"
            isGhost
            size="small"
            negativeMargin="right"
          >
            {rightActions}
          </ButtonGroup>
        </div>
      </div>
    );
  },
  {
    treeName: "Header",
  }
);

export type FooterProps = Props<"div"> & {
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  hideBorder?: boolean;
};

export const Footer = createComponent(
  ({ leftActions, rightActions, hideBorder, ...props }: FooterProps) => {
    return (
      <div
        {...props}
        className={clsx(el`footer`, props.className, {
          hideBorder,
        })}
      >
        <div className="flex items-center px-[1.5rem] h-[5.5rem]">
          <ButtonGroup isGhost negativeMargin="left" className="min-w-[2rem]">
            {leftActions}
          </ButtonGroup>
          <div className="flex-1" />
          <ButtonGroup negativeMargin="right" className="min-w-[2rem]">
            {rightActions}
          </ButtonGroup>
        </div>
      </div>
    );
  },
  {
    treeName: "Footer",
  }
);
