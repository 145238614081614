import { atom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

export type TimeRange = {
  id: string;
  start: string;
  end: string;
};

export const schedulingRequestsIsInternalAtom = atom(false);

export const useIsInternal = () => {
  return useAtomValue(schedulingRequestsIsInternalAtom);
};

export const fakeAvailabilityAtom = atomWithStorage<TimeRange[]>(
  "internal-fake-availability",
  [],
  {
    getItem: (key) => {
      const item = localStorage.getItem(key);

      if (item) {
        return JSON.parse(item) as TimeRange[];
      }

      return [];
    },
    setItem: (key, newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue));
    },
    removeItem: (key) => {
      localStorage.removeItem(key);
    },
  }
);

export const useFakeAvailability = () => {
  const isInternal = useIsInternal();
  const fakeAvailability = useAtomValue(fakeAvailabilityAtom);

  if (!isInternal || !fakeAvailability?.length) {
    return null;
  }

  return fakeAvailability;
};

export const useMappedFakeAvailability = (): TimeRange[] | null => {
  const fakeAvailability = useFakeAvailability();

  return useMemo(() => {
    if (!fakeAvailability) {
      return null;
    }

    return fakeAvailability;
  }, [fakeAvailability]);
};

export const useAvailabilityWithFake = ({
  availability,
}: {
  availability: TimeRange[];
}) => {
  const fakeAvailability = useMappedFakeAvailability();

  if (fakeAvailability?.length) {
    return fakeAvailability;
  }

  return availability;
};
