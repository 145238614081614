/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloError,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  // eslint-disable-next-line no-restricted-imports
  useMutation as useApolloMutation,
} from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useToast } from "@resource/atlas/toast/use-toast";
import { ErrorCodes } from "@resource/common";
import { useIsInternal } from "client/app/internal/(scheduling requests)/state";
import { getErrorWithGraphQLErrors } from "client/utils/apollo";
import { DocumentNode } from "graphql";
import _ from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

export enum ToastDisplayCondition {
  NONE,
  ERROR,
  SUCCESS,
  ALL,
}

interface AdditionalOptions {
  displayToastsOn?: ToastDisplayCondition;
  allowInternal?: boolean;
}

const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & AdditionalOptions
): MutationTuple<TData, TVariables> => {
  const { sendToast } = useToast();
  const isInternal = useIsInternal();
  const router = useRouter();
  const displayToastsOn = options?.displayToastsOn ?? ToastDisplayCondition.ALL;

  const [mutate, mutationData] = useApolloMutation<TData, TVariables>(
    mutation,
    {
      ...options,
      onCompleted: (data, ...rest) => {
        let hasErrors = false;

        _.forEach<FetchResult<TData, Record<string, any>, Record<string, any>>>(
          data as FetchResult<TData, Record<string, any>, Record<string, any>>,
          (mutationResponse) => {
            if (
              typeof mutationResponse === "object" &&
              mutationResponse !== null && // null is also an object in JavaScript, so we need to exclude it
              "success" in mutationResponse &&
              "message" in mutationResponse
            ) {
              if (!mutationResponse.success) {
                hasErrors = true;
              }

              const { message, success } = mutationResponse;

              if (
                displayToastsOn === ToastDisplayCondition.ALL ||
                (success &&
                  displayToastsOn === ToastDisplayCondition.SUCCESS) ||
                (!success && displayToastsOn === ToastDisplayCondition.ERROR)
              ) {
                sendToast(message, {
                  variant: success ? "success" : "error",
                });
              }
            }
          }
        );

        if (!hasErrors) {
          options?.onCompleted?.(data, ...rest);
        }
      },
      onError: (thrownErr, ...rest) => {
        const err =
          thrownErr instanceof ApolloError
            ? getErrorWithGraphQLErrors(thrownErr)
            : thrownErr;

        options?.onError?.(err, ...rest);

        if (
          err instanceof ApolloError &&
          (displayToastsOn === ToastDisplayCondition.ALL ||
            displayToastsOn === ToastDisplayCondition.ERROR)
        ) {
          for (let i = 0; i < err.graphQLErrors.length; i += 1) {
            const graphqlError = err.graphQLErrors[i];
            const explicitErrorCode = graphqlError?.extensions?.exception?.code;
            if (explicitErrorCode) {
              switch (explicitErrorCode) {
                case ErrorCodes.VALIDATION_ERROR:
                  sendToast(graphqlError.message, {
                    variant: "error",
                  });
                  break;
                default:
                  break;
              }
            }
            // TODO: Verify if this existing code works as intended as I don't think it does other than the INTERNAL_SERVER_ERROR (Salman)
            if (!explicitErrorCode && graphqlError?.extensions?.code) {
              switch (graphqlError.extensions.code) {
                case "UNAUTHENTICATED":
                  sendToast(
                    "It appears you were logged out. Please login again!",
                    {
                      variant: "error",
                    }
                  );
                  router.push("/login");
                  break;
                case "BAD_USER_INPUT":
                  sendToast("Looks like something went wrong.", {
                    description: "Please reload your page and try again.",
                    actions: [
                      {
                        label: "Reload",
                        onClick: () => window.location.reload(),
                      },
                    ],
                    variant: "error",
                  });
                  break;
                case "FORBIDDEN":
                  sendToast(
                    "It looks like you don't have permission to do that.",
                    {
                      variant: "error",
                    }
                  );
                  break;
                case "RATE_LIMIT_EXCEEDED":
                  sendToast(
                    "It looks like you've made too many requests. Please try again soon.",
                    {
                      variant: "error",
                    }
                  );
                  break;
                case "INTERNAL_SERVER_ERROR":
                  sendToast("Looks like something went wrong.", {
                    description:
                      "Please try again. If the problem persists, reach out to support.",
                    variant: "error",
                  });
                  break;
                default:
                  break;
              }
            }
          }
        }
      },
    }
  );

  const mutateWrapped = useCallback(
    async (wrappedOptions?: MutationFunctionOptions<TData, TVariables>) => {
      if (isInternal && !options?.allowInternal) {
        throw new Error("Cannot call mutation when in internal mode.");
      }

      const res = await mutate(wrappedOptions);

      return res;
    },
    [isInternal, mutate, options?.allowInternal]
  );

  return useMemo(
    () => [mutateWrapped, mutationData],
    [mutateWrapped, mutationData]
  );
};

export default useMutation;
